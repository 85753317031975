import React from "react"
import Img from "gatsby-image"
import { graphql, Link } from 'gatsby'
import Layout from "../components/Layout/Layout"
import PageHeader from '../components/Layout/PageHeader/PageHeader'
import SectionPageMargin from '../components/Layout/Section/SectionPageMargin'
import Heading2 from '../components/Type/Heading2'
import Heading4 from '../components/Type/Heading4'
import SEO from "../components/Seo/Seo"
import styled from 'styled-components'
import Paragraph1 from "../components/Type/Paragraph1"


const MakeModelBadgePage = ({ location, data, pageContext }) => {

  const makeSlug = pageContext.make.replace(/\s+/g, '-').toLowerCase();
  const modelSlug = pageContext.model.replace(/\s+/g, '-').toLowerCase();

  const slugger = (part) => {
    return part.replace(/\s+/g, '-').toLowerCase();
  }

  const badgeList = data.allValuation.distinct.map((badge, i) => {
    let badgeSlug = badge.replace(/\s+/g, '-').toLowerCase();
    return (
      <li key={i}><ButtonLink to={"/price/" + makeSlug + "/" + modelSlug + "/" + badgeSlug + "/"}>{badge}</ButtonLink></li>
    );
  });

  let breadcrumb = <div style={{ color: 'white' }}>
    Valuations: &nbsp;
    <Link style={{ color: 'white' }} to="/price/">Make</Link> &nbsp; / &nbsp;
    <Link style={{ color: 'white' }} to={slugger("/price/" + pageContext.make + "/")}>{pageContext.make}</Link> &nbsp; / &nbsp; {pageContext.model}
  </div>


  return (
    <Layout>

      <SEO title={"Price guide for " + pageContext.make + " " + pageContext.model + " cars in Australia"}
        description={"How much are " + pageContext.make + " " + pageContext.model + " cars worth in Australia? Find out here."}
        location={location}
      />

      <PageHeader marginBottom="10px"
        title={"Price guide for " + pageContext.make + " " + pageContext.model + " cars in Australia"}
        text={"How much are " + pageContext.make + " " + pageContext.model + " cars worth in Australia? Find out here."}
        breadcrumb={breadcrumb}
      />

      <SectionPageMargin marginBottom="40px">

        <Heading2>{pageContext.make} {pageContext.model}</Heading2>

        <Containter>
          <div>
              <MakeLink to={"/price/"} >
                <div style={{ alignSelf: 'center' }}>
                  <Img fixed={data.logoImg.childImageSharp.fixed}  />
                </div>
              </MakeLink>
            </div>

          <SelectBox>
            <Heading4 color="#3479ae" style={{margin: '0px'}}>Select a Badge</Heading4>
            <ul style={{ listStyleType: 'none', padding: '0', margin: '0' }}>
              {badgeList}
            </ul>
          </SelectBox>
        </Containter>

        <Paragraph1>
          If we are missing the car you're looking for, it is due to a lack of 
          data in our valuations database to provide a confident valuation. 
          Please make an <Link to="/enquiry/">enquiry with us</Link>, and we'll provide a custom 
          Valuation Report for your car.
        </Paragraph1>

      </SectionPageMargin>

    </Layout>
  )
}

export default MakeModelBadgePage

const Containter = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 40px;
`;

const SelectBox = styled.div`

`;

const MakeLink = styled(Link)`
  display: block;
	border: 1px solid #CCCCCC;
	border-radius: 5px;
	text-align: center;
	padding: 10px;
	background: white;
  margin-right: 30px;
  margin-bottom: 10px;

  @media (max-width: 600px) {
    width: 300px;
  }

	&:hover {
		box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
	}

`

const ButtonLink = styled(Link)`
	border: 1px solid #CCCCCC;
	border-radius: 5px;
	text-align: center;
	padding: 10px;
  width: 300px;
	background: #f8f8f9;
  margin-bottom: 8px;
  text-decoration: none;

  display: block;

	&:hover {
		box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
    background: #3479ae;
    color: white;
	}

`



export const PageQuery = graphql`
  query($make: String!, $model: String!, $logo: String!) {

    allValuation(filter: {make: {eq: $make}, model: {eq: $model} }) {
      distinct(field: badge)
    }

    logoImg: file(relativePath: { eq: $logo }) {
      childImageSharp {
        fixed(width: 150) {
        ...GatsbyImageSharpFixed
        }
      }
    }

  }`

